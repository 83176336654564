import { ReactElement } from 'react';
import { NextIconWithNotification } from 'components/NextImage/NextIconWithNotification';

import cx from 'classnames'
import styles from './MenuItem.module.scss';

export type menuItemProps = {
  className?: string;
  iconSrc?: string;
  iconWidth?: number | string;
  iconHeight?: number | string;
  notification?: number;
  children?: ReactElement;
  onClick?: () => void;
  title?: string;
  alt?: string;
};

export const MenuItem = ({
  className,
  title,
  iconSrc,
  iconWidth = '24',
  iconHeight = '24',
  notification,
  onClick = () => null,
  children,
  alt,
}: menuItemProps) => {
  return (
    <div className={cx(styles.item, className)} onClick={onClick}>
      {iconSrc && (
        <NextIconWithNotification
          alt={alt || title}
          customNotificationClass={styles.notification}
          height={iconHeight}
          notification={notification}
          src={iconSrc}
          width={iconWidth}
        />
      )}
      {title && <p>{title}</p>}
      {children && children}
    </div>
  );
};
